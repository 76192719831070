import requestAuth from '@/utils/request-auth'
import requestLogin from '@/utils/request-login'

//登录获取token
export function login(params) {
    return requestAuth.post('/api-uaa/oauth/token', params)
}

//判断token是否失效
export function checkToken(params) {
    return requestAuth.post('/api-uaa/oauth/check_token', params)
}

//发送登录认证短信
export function smsLoginSend(phone) {
    return requestLogin.get('/api-uaa/validata/sms/login-code/' + phone)
}

//微信扫码获取uuid
export function getUuid(params) {
    return requestLogin.get('/api-uaa/acl/wx/qrcode/getUUID', params)
}

//微信扫码检查uuid状态
export function checkUuid(params) {
    return requestLogin.get('api-uaa/acl/wx/qrcode/check-uuid', params)
}