import Vue from 'vue'
import axios from 'axios'
import router from "../router"
import store from "../store"
import { message } from 'ant-design-vue'
import qs from 'qs'
import { setCookie } from "@/utils/cookie.js"

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASEURL,
    // 超时
    timeout: 5000
})

service.param = (data = {}, contentType = 'application/x-www-form-urlencoded') => {
    return contentType === 'application/x-www-form-urlencoded' ? qs.stringify(data) : (contentType === 'application/json' ? JSON.stringify(data) : data);
}

// request拦截器
service.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Basic d2ViQXBwOndlYkFwcA==';
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        if (code == 200) {
            return res.data
        } else {
            store.commit("setCodeUrl");
            message.error(res.data.msg)
            return Promise.reject('error')
        }
    },
    error => {
        store.commit("setCodeUrl");
        if (error.response.status === 500) {
            message.error("请求异常")
        } else {
            let { msg } = error.response.data
            if (error.response.data.error === "invalid_token") {
                router.push("/login")
                setCookie("access_token", null);
                setCookie("token_type", null);
                setCookie("refresh_token", null);
                setCookie("scope", null);
                Vue.ls.set("access_token", null);
                Vue.ls.set("token_type", null);
                Vue.ls.set("refresh_token", null);
                Vue.ls.set("scope", null)
            } else {
                message.error(msg)
            }

        }

        return Promise.reject(error)
    }
)

function post(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url, service.param(params)).then(
            (response) => {
                resolve(response)
            },
            (err) => {
                reject(err)
            }
        )
    })
}


export default {
    post
}