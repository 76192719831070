import axios from 'axios'
import Vue from 'vue'
import { message } from 'ant-design-vue'
import { saveAs } from 'file-saver'
import { setCookie } from "@/utils/cookie.js"
import router from "../router"


// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASEURL,
    // 超时
    timeout: 5000
})


// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    config.headers = {
        "Authorization": Vue.ls.get('token_type') + " " + Vue.ls.get('access_token'),
        'Content-Type': "application/json;charset=UTF-8"
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        if (code == 200) {
            return res
        }
        //网盘单独判断
        else if (code == -4321) {
            return res
        } else {
            message.error(res.data.msg)
            return Promise.reject('error')
        }
    },
    error => {
        if (error.response.status === 401 || error.response.status === 400) {
            message.error("该账号登录状态已失效或已在其他设备登录，请重新登录")
            router.push("/login")
            setCookie("access_token", null);
            setCookie("token_type", null);
            setCookie("refresh_token", null);
            setCookie("scope", null);
            Vue.ls.set("access_token", null);
            Vue.ls.set("token_type", null);
            Vue.ls.set("refresh_token", null);
            Vue.ls.set("scope", null);
        } else if (error.response.status === 500) {
            message.error("请求异常")
        } else {
            message.error(error.response.data.msg)
        }

        return Promise.reject(error)
    }
)

//转换参数格式
function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof(value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof(value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

// 通用下载方法
function download(url, params, fileName) {
    return service.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob'
    }).then(async(data) => {
        const blob = new Blob([data.data])
        saveAs(blob, fileName)
    }).catch((r) => {
        console.error(r)
        message.error("下载异常")
    })
}

function post(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url, params).then(
            (response) => {
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: params }).then((response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function put(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.put(url, params).then(
            (response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

function remove(url, data) {
    return new Promise((resolve, reject) => {
        service
            .delete(url, { data })
            .then((response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


export default {
    get,
    post,
    put,
    remove,
    download
}