import request from '@/utils/request'

//获取字典列表
export function getDictList(params) {
    return request.get('/api-system/dict/key', params)
}

//新增或修改字典
export function dictAddOrUpdate(params) {
    return request.post('/api-system/dict/key/saveOrUpdate', params)
}

//获取字典详情
export function getDict(id) {
    return request.get('/api-system/dict/key/' + id)
}

//删除字典
export function dictRemove(params) {
    return request.remove('/api-system/dict/key/deleteBatch', params)
}

//获取字典数据列表
export function getDictDataList(params) {
    return request.get('/api-system/dict/value', params)
}

//新增或修改字典数据
export function dictDataAddOrUpdate(params) {
    return request.post('/api-system/dict/value/saveOrUpdate', params)
}

//获取字典数据详情
export function getDictData(id) {
    return request.get('/api-system/dict/value/' + id)
}

//删除字典数据
export function dictDataRemove(params) {
    return request.remove('/api-system/dict/value/deleteBatch', params)
}