import request from '@/utils/request'

//获取消息通知数量
export function getMessageNotificationCount(params) {
    return request.get('/api-uaa/message/audit-message/un-read/count',params)
}

//获取消息通知列表
export function getMessageNotificationList(params) {
    return request.get('/api-uaa/message/audit-message/un-read',params)
}

//删除消息
export function delMessageNotification(id) {
    return request.remove('/api-uaa/message/audit-message/delete/'+id)
}

//获取历史消息通知列表
export function getHistoryMessageNotificationList(params) {
    return request.get('/api-uaa/message/audit-message/read',params)
}

//审核消息
export function reviewMessageNotification(params) {
    return request.put('/api-uaa/message/audit-message/review',params)
}