import { getDictDataList } from "@/api/setting/dict.js";
export default function registerMixins(Vue) {
    Vue.mixin({
        data() {
            return {

            }
        },
        methods: {
            //获取字典标签列表
            getDictDataList(keyType) {
                return getDictDataList({isPage:false,keyType:keyType}).then((res) => {
                    return res
                });
            },
            //字典翻译
            dictTranslation(data,value){
                if(data&&data.length>0&&value){
                    return data.find(item=>item.valueData===value).valueName
                }
            }
        },
        created() {

        },
    })
}