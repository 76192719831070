import Layout from '@/views/layout'
//静态路由
const constantRouter = [{
        path: '/',
        redirect: '/enter',
    },
    //欢迎页
    {
        path: '/welcome',
        name: 'welcome',
        meta: {
            title: '欢迎',
        },
        component: () =>
            import ('@/views/welcome'),
        redirect: '/login',
        children: [
            //登录
            {
                path: '/login',
                name: 'login',
                meta: {
                    title: '登录',
                },
                component: () =>
                    import ('@/views/login'),
            },
            //忘记密码
            {
                path: '/forget',
                name: 'forget',
                meta: {
                    title: '忘记密码',
                },
                component: () =>
                    import ('@/views/forget'),
            },
            //注册
            {
                path: '/register',
                name: 'register',
                meta: {
                    title: '注册',
                },
                component: () =>
                    import ('@/views/register'),
            },
            //微信扫码登录
            {
                path: '/wx-login',
                name: 'wxLogin',
                meta: {
                    title: '微信扫码登录',
                },
                component: () =>
                    import ('@/views/wx-login'),
            },
        ]
    },
    //入口页
    {
        path: '/enter',
        name: 'enter',
        meta: {
            title: '首页',
        },
        component: () =>
            import ('@/views/enter'),
    },
    //个人中心
    {
        path: '/user-center',
        name: 'userCenter',
        meta: {
            title: '个人中心',
        },
        component: () =>
            import ('@/views/user-center'),
        redirect: '/user-center/base-setting',
        children: [
            //基本设置
            {
                path: '/user-center/base-setting',
                name: 'baseSetting',
                meta: {
                    title: '基本设置',
                },
                component: () =>
                    import ('@/views/user-center/baseSetting'),
            },
            //修改密码
            {
                path: '/user-center/repair-pasword',
                name: 'repairPasword',
                meta: {
                    title: '修改密码',
                },
                component: () =>
                    import ('@/views/user-center/repairPasword'),
            },
            //消息通知
            {
                path: '/user-center/message-notification',
                name: 'messageNotification',
                meta: {
                    title: '消息通知',
                },
                component: () =>
                    import ('@/views/user-center/messageNotification'),
            },
        ]
    },
    //《隐私政策》
    {
        path: '/privacy',
        name: 'privacy',
        meta: {
            title: '隐私政策',
        },
        component: () =>
            import ('@/views/privacy'),
    },
    //《服务协议》
    {
        path: '/agreement',
        name: 'agreement',
        meta: {
            title: '服务协议',
        },
        component: () =>
            import ('@/views/agreement'),
    },
    //404
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () =>
            import ('@/views/unfound/404'),
    },
]

//需要权限的路由
const needPermissRouter = [
    //教室终端详情
    {
        path: '/device/terminal-detail',
        name: 'terminalDetail',
        meta: {
            title: '教室终端详情'
        },
        permissions: ['device:terminal:query'],
        component: () =>
            import ('@/views/device/terminalDetail'),
    },
    //权限管理
    {
        path: '',
        meta: {
            title: '权限管理',
        },
        component: Layout,
        children: [{
            path: '/acl/menu-data',
            name: 'menuData',
            meta: {
                title: '菜单管理'
            },
            permissions: ['acl:menu:query'],
            component: () =>
                import ('@/views/acl/menuData'),
        }, ]
    },
    //基础信息
    {
        path: '',
        meta: {
            title: '基础信息',
        },
        component: Layout,
        children: [{
            path: '/basic-info/building-data',
            name: 'buildingData',
            meta: {
                title: '楼栋管理-教室'
            },
            permissions: ['basicInfo:building:query'],
            component: () =>
                import ('@/views/basic-info/buildingData'),
        }, ]
    },
    //基础配置
    {
        path: '',
        meta: {
            title: '基础配置',
        },
        component: Layout,
        children: [{
            path: '/setting/dict-data',
            name: 'dictData',
            meta: {
                title: '字典数据'
            },
            permissions: ['setting:dict:query'],
            component: () =>
                import ('@/views/setting/dictData'),
        }, ]
    },
    //版本管理
    {
        path: '',
        meta: {
            title: '版本管理',
        },
        component: Layout,
        children: [{
            path: '/version/parameter-data',
            name: 'parameterData',
            meta: {
                title: '参数数据'
            },
            permissions: ['version:parameter:query'],
            component: () =>
                import ('@/views/version/parameterData'),
        }, ]
    },
]

export default {
    constantRouter,
    needPermissRouter
}