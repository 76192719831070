import request from '@/utils/request'

//获取学校列表
export function getSchoolList(params) {
    return request.get('/api-school/school', params)
}

//新增或修改学校
export function schoolAddOrUpdate(params) {
    return request.post('/api-school/school/saveOrUpdate', params)
}

//获取学校详情
export function getSchool(id) {
    return request.get('/api-school/school/'+id)
}

//删除学校
export function schoolRemove(params) {
    return request.remove('/api-school/school', params)
}

//根据区域获取学校列表
export function getSchoolListByRegion(params) {
    return request.get('/api-school/school/getSchoolByRegions', params)
}

//根据schoolNum获取学校名称
export function getSchoolNameBySchoolNum(schoolNum) {
    return request.get('/api-school/school/findBySchoolNum/'+schoolNum)
}