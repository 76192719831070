import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
Vue.use(VueRouter)

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


import store from '@/store/index'
import Layout from "@/views/layout"

import { checkToken } from '@/api/login';

//静态路由path数组
let constantRouterList = []
    //递归数组，得到所有菜单的子节点
function getChildren(data) {
    data.map((item) => {
        if (item.children && item.children.length > 0) {
            getChildren(item.children);
        } else {
            constantRouterList.push(item.path);
        }
    });
    return constantRouterList;
}

const router = new VueRouter({
    mode: 'history',
    // base: process.env.VUE_APP_BASEURL,
    routes: routes.constantRouter,
})


//路由守卫，路由跳转之前的钩子
router.beforeEach((to, from, next) => {
    NProgress.start()
        /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //访问的是静态路由
    if (getChildren(routes.constantRouter).includes(to.path)) {
        next()
    }
    //访问的是动态路由
    else {
        //判断是否有token,有token
        if (Vue.ls.get('access_token')) {
            //判断token有没有失效
            checkToken({ token: Vue.ls.get('access_token') }).then(response => {
                if (response.active) {
                    //判断拉取动态路由数组，如果数组为空拉取数组
                    if (store.state.dynamicList.length === 0) {
                        store.dispatch('getDynamicList').then((res) => {
                            let arr = []
                            let permissionList = []
                                //减少层级，去掉最外层的盒子
                            res.forEach(item => {
                                    //盒子就是路由
                                    if (item.component != null && item.component != "") {
                                        arr.push(item)
                                    }
                                    //目录或者菜单
                                    if (item.children && item.children.length > 0) {
                                        item.children.forEach(r => {
                                            arr.push(r)
                                        })
                                    }
                                })
                                //处理动态路由组件处理数据
                            arr.forEach(item => {
                                    //盒子且不是外链
                                    if (item.type == 0 && item.isFrame == 1) {
                                        const component = item.component
                                        item.component = (resolve) => require(['@/views' + component], resolve)
                                        let meta = {
                                            title: ""
                                        }
                                        item.meta = meta
                                        meta.title = item.menuName
                                        permissionList.push(item)
                                    }
                                    //目录
                                    else if (item.type == 1 && item.component === null) {
                                        item.component = Layout
                                        let meta = {
                                            title: ""
                                        }
                                        meta.title = item.menuName
                                        item.meta = meta
                                        if (item.children && item.children.length > 0) {
                                            item.children.forEach(r => {
                                                const component = r.component
                                                r.component = (resolve) => require(['@/views' + component], resolve)
                                                let meta = {
                                                    title: ""
                                                }
                                                meta.title = r.menuName
                                                r.meta = meta
                                            })
                                        }
                                        permissionList.push(item)
                                    }
                                    //菜单
                                    else {
                                        let permiss = {
                                            path: '',
                                            component: Layout,
                                            children: [],
                                            meta: {
                                                title: "首页"
                                            }
                                        }
                                        const component = item.component
                                        item.component = (resolve) => require(['@/views' + component], resolve)
                                        let meta = {
                                            title: ""
                                        }
                                        meta.title = item.menuName
                                        item.meta = meta
                                        permiss.children.push(item)
                                        permissionList.push(permiss)
                                    }
                                })
                                //判断权限，添加权限路由
                            let userInfo = Vue.ls.get("userInfo")
                            routes.needPermissRouter.forEach(item => {
                                    //带侧边栏的有权限路由
                                    if (item.children) {
                                        item.children.forEach(r => {
                                            if (userInfo.permissions.includes(r.permissions[0])) {
                                                permissionList.push(item)
                                            }
                                        })
                                    }
                                    //不带侧边栏的有权限路由
                                    else {
                                        if (userInfo.permissions.includes(item.permissions[0])) {
                                            permissionList.push(item)
                                        }
                                    }
                                })
                                //添加404匹配（最后添加）
                            let notFound = {
                                path: '*',
                                name: 'notFound',
                                meta: {
                                    title: 'notFound',
                                },
                                component: () =>
                                    import ('@/views/unfound/404'),
                            }
                            permissionList.push(notFound)
                                //重新匹配路由后添加路由
                            router.matcher = new VueRouter({
                                mode: 'history',
                                routes: routes.constantRouter,
                            }).matcher;
                            permissionList.forEach(item => {
                                    router.addRoute(item)
                                })
                                // next({...to, replace: true })
                            next({...to })
                        })
                    }
                    //已经拉取了可以访问
                    else {
                        next()
                    }
                }
            })
        }
        //没有token
        else {
            next({ path: "/login" })
        }
    }

})

router.afterEach(() => {
    NProgress.done()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router