import registerFilters from './filter'
import registerMixins from './mixin'
import registerDirective from './directive'

export default {
    install(Vue){
        registerMixins(Vue)

        registerFilters(Vue)

        registerDirective(Vue)
    }
}