import request from '@/utils/request'

//获取所有的盒子菜单
export function getPermissionBox(params) {
    return request.get('/api-user/menus/findOnes', params)
}

//获取当前用户拥有的的盒子菜单
export function getCurrentPermissionBox(params) {
    return request.get('/api-user/menus/box/current', params)
}

//新增或修改菜单
export function menuAddOrUpdate(params) {
    return request.post('/api-user/menus/saveOrUpdate', params)
}

//获取菜单详情
export function getMenu(id) {
    return request.get('/api-user/menus/detail/'+id)
}

//删除菜单
export function menuRemove(params) {
    return request.remove('/api-user/menus', params)
}

//根据父级id获取嵌套菜单
export function getChildrenMenu(params) {
    return request.get('/api-user/menus/findAlls', params)
}

//获取当前用户的动态路由菜单
export function getDynamicList(params){
    return request.get('/api-user/menus/getRouters', params)
}

//获取盒子id获取用户路由
export function getLayoutList(params){
    return request.get('/api-user/menus/current', params)
}