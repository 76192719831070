import Vue from 'vue'

import less from 'less'
Vue.use(less)
//自定义的样式
import '@/assets/css/common.css'

//ant 框架
import Antd from 'ant-design-vue'
//ant的样式引入
import 'ant-design-vue/dist/antd.less'
Vue.use(Antd)

//全局使用百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  /* 申请的百度密钥，可以在百度地图官网申请 */
  ak: 'DCKHei6xfXCe3zxCxXiwi6qgezFZXhX1',
})

//全局使用自定义插件
import plugin from '@/plugin'
Vue.use(plugin)

//全局使用ls(缓存)
import VueStorage from 'vue-ls'
Vue.use(VueStorage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
})


import App from './App.vue'

Vue.config.productionTip = false


import router from './router'
import store from './store'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
