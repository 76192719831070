import Vue from "vue"
let directives = {
  hasPermi: {
    inserted: function (el, binding, vnode) {
      const { value } = binding
      let permissions = Vue.ls.get("userInfo").permissions
      let hasPermissions = permissions.some(item => value.includes(item))
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
}


export default function registerDirective(Vue) {
  Object.keys(directives).forEach(key => {
    Vue.directive(key, directives[key])
  })
}