import axios from 'axios'
import { message } from 'ant-design-vue'


// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASEURL,
    // 超时
    timeout: 8000
})


// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    config.headers = {
        'Content-Type': "application/json;charset=UTF-8"
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        if (code == 200) {
            return res
        } else {
            message.error(res.data.msg)
            return Promise.reject('error')
        }
    },
    error => {
        if (error.response.status === 500) {
            message.error("请求异常")
        } else {
            message.error(error.response.data.msg)
        }

        return Promise.reject(error)
    }
)

function post(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url, params).then(
            (response) => {
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: params }).then((response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function put(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.put(url, params).then(
            (response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            },
            (err) => {
                reject(err)
            }
        )
    })
}

function remove(url, data) {
    return new Promise((resolve, reject) => {
        service
            .delete(url, { data })
            .then((response) => {
                // store.state.loadding = false;//在这里对返回的数据进行处理
                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


export default {
    get,
    post,
    put,
    remove
}