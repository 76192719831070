<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  components: {},
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style></style>
