let filter = {
    fmtTime: val => {
        let d = new Date(val);
        let now = new Date();
        let s = (now - d) / 1000;
        if (s < 60) return "刚刚";
        else if (s < 60 * 60) return Math.floor(s / 60) + "分钟前";
        else if (s < 60 * 60 * 24) return Math.floor(s / 3600) + "小时前";
        else return val
    },
    fmtGender(val) {
        return ['男', '女'][val]
    },
    fmtStatus(val) {
        return ['启用', '禁用'][val]
    }
}

export default function registerFilters(Vue) {
    Object.keys(filter).forEach(key => {
        Vue.filter(key, filter[key])
    })
}