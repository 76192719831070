import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { getDynamicList } from '@/api/acl/menu.js'
import { v4 as uuidv4 } from "uuid";
export default new Vuex.Store({
  state: {
    dynamicList: [],
    uuid:"",
    codeUrl:"",
    forgetMobile:"",
    registerForm:{}
  },
  mutations: {
    setList: (state, data) => {
      state.dynamicList = data
    },
    setCodeUrl: (state, data) => {
      state.uuid = uuidv4().replace(/-/g, "");
      state.codeUrl = process.env.VUE_APP_BASEURL + "/api-uaa/validata/code/" + state.uuid;
    },
  },
  actions: {
    //获取动态路由权限列表
    async getDynamicList({ commit }) {
      // 取后台路由
      let res = await getDynamicList();
      return new Promise((resolve) => {
        // 存储权限列表
        const data = res.data
        commit('setList', data)
        resolve(data)
      })
    },
  },
  getters: {

  },
  modules: {},
})
